import React from "react";
import { graphql } from "gatsby";
import SEO from "../components/Seo";
import Layout from "../components/Layout";
import { Helmet } from "react-helmet";
import FlexHeroHomepage from "../query/blocks/FlexHeroHomepage";
import IStarpiHomepage from "../interfaces/IStrapiHomepage";
import IndexFeatures from "../components/IndexFeatures";
import FlexDividerLarge from "../query/blocks/FlexDividerLarge";
import FlexClients from "../query/blocks/FlexClients";
import FlexContentBanner from "../query/blocks/FlexContentBanner";
import FlexUvp from "../query/blocks/FlexUvp";
import FlexTextColumn from "../query/blocks/FlexTextColumn";
import getImageUrl from "../lib/get-image-url";
import FlexCtaSection from "../query/blocks/FlexCtaSection";

interface IProps {
  data: { 
    strapiHomepage: IStarpiHomepage
  };
}

const IndexPage: React.FC<IProps> = ({ data: { strapiHomepage } }) => {
  const {
    heroImage,
    heroImageMobile,
    titleFirtsPart,
    titleTypingItem,
    titleSecondPart,
    subittle,
    featuresSubheading,
    featuresItem,
    companyLogo,
    companyLogosSubheading,
    valueHeading,
    valueText,
    valueImage,
    valueImageMobile,
    valueParagraph,
    valueSubheading,
    differenceHeading,
    differenceSubheading,
    differenceItem,
    ctaHeading,
    ctaSubheading,
    ctaImage,
    ctaFirstButtonText,
    ctaFirstButtonUrl,
    ctaSecondButtonText,
    ctaSecondButtonUrl,
    seo,
  } = strapiHomepage;

  const {
    title: seoTitle,
    description: seoDescription,
    image: seoImage,
    twitterImage: seoTwitterImage,
  } = seo || {};
  
  const schema = {
    "@context": "https://schema.org",
    "@type": "Organization",
    url: `https://${process.env.GATSBY_PUBLIC_DOMAIN}/`,
    logo: seoImage?.localFile?.publicURL ? `https://${process.env.GATSBY_PUBLIC_DOMAIN}${seoImage?.localFile?.publicURL}` : undefined,
  };

  return (
    <Layout
      headerLocation="home"
    >
      <Helmet>
        <meta name="facebook-domain-verification" content="ijw7kdxjvhcye8x74sojtdmxtzx9qi" />
      </Helmet>
      <SEO
        title={seoTitle}
        description={seoDescription}
        url="/"
        image={
          seoImage?.localFile?.publicURL 
            ? seoImage?.localFile?.publicURL 
            : undefined
        }
        twitterImage={
          seoTwitterImage?.localFile?.publicURL 
            ? seoTwitterImage?.localFile?.publicURL 
            : undefined
        }
        schemaMarkup={schema}
      />
      <FlexHeroHomepage
        block={{
          imageDesktop: heroImage,
          imageMobile: heroImageMobile,
          subtitle: subittle,
          titleFirstPart: titleFirtsPart,
          titleSecondPart,
          titleTypingItem,
        }}
        index={0}
      />
      <IndexFeatures
        items={featuresItem}
        message={featuresSubheading}
      />
      <FlexDividerLarge />
      <FlexClients
        block={{ 
          heading: companyLogosSubheading,
          clients: companyLogo.map(item => ({
            image: { 
              sourceUrl: getImageUrl(item.icon),
              alt: item.icon.localFile.alternativeText,
            }
          }))
        }}
      />
      <FlexContentBanner
        block={{
          heading: valueHeading,
          subheading: valueText,
          backgroundImageDesktop: {
            imageFile: valueImage.localFile,
            altText: valueImage.alternativeText,
          },
          backgroundImageMobile: {
            imageFile: valueImageMobile.localFile,
            altText: valueImage.alternativeText,
          },
        }}
        index={4}
      />
      <FlexTextColumn
        block={{
          heading: valueSubheading,
          text: valueParagraph,
          reverseElements: true,
          button: null,
          outline: true
        }}
        index={5}
      />
      <FlexDividerLarge />
      <FlexUvp 
        block={{
          heading: differenceHeading,
          subheading: differenceSubheading,
          items: differenceItem.map((item, index) => ({
            id: index,
            icon: { 
              sourceUrl: getImageUrl(item.icon),
              altText: item.icon.localFile.alternativeText,
            },
            heading: item.heading,
            description: item.text
          }))
        }}
        divHeading={true}
        index={6}
      />
      <FlexCtaSection
        block={{
          heading: ctaHeading,
          subheading: ctaSubheading,
          darkButton: { 
            url: ctaFirstButtonUrl,
            title: ctaFirstButtonText,
          },
          lightButton: { 
            url: ctaSecondButtonUrl,
            title: ctaSecondButtonText,
          },
          image: { 
            imageFile: ctaImage.localFile,
          },
        }}
        index={7}
      />
      {/* <FlexContentBlocks contentBlocks={WP_ACF} pageData={data} /> */}
    </Layout>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query {
    strapiHomepage {
      heroImage {
        alternativeText
        localFile {
          publicURL
          childImageSharp {
            gatsbyImageData(
              layout: CONSTRAINED
              width: 800
              quality: 100
              formats: [AUTO, WEBP]
              placeholder: NONE
            )
          }
        }
      }
      heroImageMobile {
        alternativeText
        localFile { 
          publicURL
          childImageSharp {
            gatsbyImageData(
              layout: CONSTRAINED
              width: 300
              quality: 90
              formats: [AUTO, WEBP]
              placeholder: NONE
            )
          }
        }
      }
      titleFirtsPart
      titleTypingItem { 
        text
      }
      titleSecondPart
      subittle
      featuresItem { 
        icon {
          alternativeText
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                height: 58
                placeholder: NONE
              )
            }
          }
        }
        text
      }
      featuresSubheading
      companyLogo { 
        icon {
          alternativeText
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                width: 150
                height: 72
                quality: 90
                formats: [AUTO, WEBP]
                placeholder: NONE
              )
            }
          }
        }
      }
      companyLogosSubheading
      valueImage { 
        alternativeText
        localFile {
          childImageSharp {
            gatsbyImageData(
              layout: CONSTRAINED
              width: 1400
              quality: 90
              formats: [AUTO, WEBP]
              placeholder: NONE
            )
          }
        }
      }
      valueImageMobile {
        alternativeText
        localFile {
          childImageSharp {
            gatsbyImageData(
              layout: CONSTRAINED
              width: 800
              quality: 90
              formats: [AUTO, WEBP]
              placeholder: NONE
            )
          }
        }
      }
      valueHeading
      valueText
      valueSubheading
      valueParagraph
      differenceHeading
      differenceItem {
        icon { 
          alternativeText
          localFile {          
            url
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                width: 80
                quality: 90
              )
            }
          }
        }
        heading
        text
      }
      differenceSubheading
      ctaImage {
        alternativeText
        localFile {
          childImageSharp {
            gatsbyImageData(
              layout: CONSTRAINED
              width: 555
              quality: 90
              formats: [AUTO, WEBP]
              placeholder: NONE
            )
          }
        }
      }
      ctaHeading
      ctaSubheading
      ctaFirstButtonText
      ctaFirstButtonUrl
      ctaSecondButtonText
      ctaSecondButtonUrl
      seo { 
        title
        description
        image {
          localFile {
            publicURL
          }
        }
        twitterImage {
          localFile {
            publicURL
          }
        }
      }
    }
  }
`;
