interface Image { 
  localFile: { 
    url: string
    childImageSharp: {
      gatsbyImageData: any
    } | null
  }
}

export default function getImageUrl(image: Image) {
  if (!image.localFile.childImageSharp) { 
    return image.localFile.url;
  }

  return image.localFile.childImageSharp.gatsbyImageData.images.fallback.src;
}